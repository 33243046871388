import { IWebStorage } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { BasicStorage } from './BasicStorage';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { getLogLevelFromControllerConfig } from '../loggers/debugLogger';
import { ConsoleLogger } from '../loggers';
import { PlatformStorage } from './PlatformStorage';

export class GroupsStorage extends PlatformStorage {
  static fromControllerConfig(
    controllerConfig: ControllerParams['controllerConfig'],
  ): GroupsStorage {
    let storage: IWebStorage;
    try {
      storage = controllerConfig.platformAPIs.storage.session;
      ConsoleLogger.mode = getLogLevelFromControllerConfig(controllerConfig);
    } catch (e) {
      console.log('Failed to get storage from controller config platformAPIs');
      storage = new BasicStorage();
    }
    return new GroupsStorage(storage);
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(storage: IWebStorage) {
    super(storage);
  }

  getGroup(groupId: string): ApiTypes.v1.GroupResponse | null {
    try {
      const _group = this.storage.getItem(groupId);
      if (!_group) {
        ConsoleLogger.log(`${groupId} is not in the storage`);
        return null;
      }
      const group = JSON.parse(_group) as ApiTypes.v1.GroupResponse;
      const isValidGroup = group.groupId === groupId || group.slug === groupId;
      ConsoleLogger.log(`${groupId} isValidGroup ${isValidGroup}`);
      return (isValidGroup && group) || null;
    } catch (e) {
      ConsoleLogger.log('Failed to get group from storage');
    }
    return null;
  }

  removeGroup(groupId: string): void {
    const group = this.getGroup(groupId);
    if (group) {
      const { groupId: id, slug } = group;
      this.storage.removeItem(id!);
      this.storage.removeItem(slug!);
    }
  }

  setGroup(group: ApiTypes.v1.GroupResponse): void {
    try {
      const { groupId, slug } = group;
      const cachedGroup = JSON.stringify(group);
      this.storage.setItem(groupId!, cachedGroup);
      this.storage.setItem(slug!, cachedGroup);
    } catch (e) {
      console.log('Failed to write a group to storage');
    }
  }
}
